html {
  background: #2b2b40;
}

html, body {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Helvetica Neue", "Helvetica", "Arial";
}

a {
  color: #00a4ca;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  width: 100%;
}

h1 strong, h2 strong {
  font-weight: 700;
}

h1 {
  font-weight: 300;
  font-size: 2.3em;
  margin: 0;
}

h2 {
  font-weight: 300;
  font-size: 2.2em;
  margin: 0 0 15px 0;
}

h3 {
  margin: 20px 0 10px 0;
}


p, address {
  font-size: 1.15rem;
  color: #666;
  margin-bottom: 20px;
  font-weight: 300;
  line-height: 1.8em;
  text-align:justify;
}

.prlist {
    margin-left:20px;
    li{
      margin-left:10px;
      color:#666;
      padding:7px 8px;
      }
    }
.comhead{
    font-size:1.6em;
    color:#666;
    border-bottom:1px solid #ccc;
    padding-bottom:10px;
    }
  