.staff {
  padding: 0;
  list-style: none;
  @extend %flexbox;
  @include flex-flow(wrap);
  text-align: center;
  li {
    padding: 30px 20px;
    box-sizing: border-box;
    width: 100%;

    @media #{$tablet} {
      @include flex(1, 1, 45%);
    }

    @media #{$desktop} {
      @include flex(1, 1, 29%);
    }
  }

  .square-image {
    width: 200px;
    height: 200px;
      img {
        border-radius: 200px;
      }
  }
.zoom{  -moz-transform: scale(0.9); -webkit-transform: scale(0.9); transform: scale(0.9); transition:0.7s ease all; -webkit-transition:0.7s ease all; -moz-transition:0.7s ease all;}

.zoom:hover{ -moz-transform: scale(1.2); -webkit-transform: scale(1.2); transform: scale(1.2);}
  img {
    box-shadow: 0 10px 20px rgba(0,0,0,.19),0 6px 6px rgba(0,0,0,.23);
  }

  .name {
    font-size: 1.3em;
    margin-top: 25px;
  }

  .position {
    color: #666;
  }
  dt {
    color:#444;
    padding:7px 0px;
      }
}
.socialline {
    border-top:2px solid #EDEDED;
    margin-top: 20px;
    padding-top: 10px;
    a {
        color:#dd3333;
        }
    }
.tocenter{text-align:center;}