.button a, input[type=submit] {
  color: #fff;
  text-decoration: none;
  padding: 10px 30px;
  background: $brand-color;
  border-radius: 3px;
  border: 1px solid rgba(255,255,255,.5);
  transition: .2s ease-in-out;
}

.button a:hover, input[type=submit]:hover {
  border: 1px solid #fff;
  background: $secondary-brand-color;
  cursor: pointer;
}

.button.alt a {
  background: rgba(255,255,255,0.15);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 16px 50px;
}

.button.alt a:hover {
  background: #fff;
  color: $brand-color;
  font-weight:bold;
}

textarea, input, button, select { font-family: inherit; font-size: inherit; }

input[type=submit] {
  margin: 20px 0 0 0;
}

label, input, textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  height: 150px;
}

label {
  margin: 20px 0 5px 0;
}

input, textarea {
  padding: 10px;
  font-size: 1em;
}

input, textarea {
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  border: 1px solid #DDDDDD;
}

input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, textarea:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
