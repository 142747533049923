.bottom-cta {
  background: linear-gradient(to bottom, #413185 0%, #1788bf 100%);
  color: #fff;
  text-align: center;
  margin: -6px 0px;
  padding: 100px 0;

  h2 {
    margin-bottom: 50px;
  }
}
.features-section-waves {
    background:#f5f5f5;
    padding:0px;
    margin:0px;
    }

.services {
  @media #{$desktop} {
    @include columns(3);
  }
  color: #1e1e1e;
  text-align:center;
  div {
    break-inside: avoid;
    padding: 15px 0;
    justify-content:center;
    div {
        vertical-align:middle;
        display:flex;
        }
  }

  dt {
    font-weight: bold;
    margin: 10px 0 15px 0;
    font-size:1.3em;
    color:#199eb8;
  }

  dd {
    padding: 0;
    margin: 0;
    line-height:1.6;
  }
}
.services .feature-icon {
    width: 70px;
    height: 40px;
    margin-bottom: 15px;
    background: #f62958;
    color:#fff;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    display: inline-block;
    border:2px solid #f41212;
    -webkit-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.services .feature-icon:hover {
background: rgba(0,0,0,0);
	color: #f41212;
	box-shadow: inset 0 0 0 3px #f62958;
	-webkit-transform: rotate(360deg);
	-ms-transform: rotate(360deg);
	transform: rotate(360deg);
}
.services .feature-icon:after {
	-webkit-transform: scale(1.3);
	-moz-transform: scale(1.3);
	-ms-transform: scale(1.3);
	transform: scale(1.3);
	opacity: 0;
}
.card {
    position: relative;
    padding: 25px 20px !important;
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    margin-bottom: 40px;
    overflow: hidden;
    background:#fafafa;
    box-shadow:0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);}
.card:hover{
    box-shadow: inset 0 0 0 1px #f41212;
    border: 1px solid #e6e6e6;
    }
.testimonial {
  background: #f5f5f5;
  margin: 0;
  padding: 100px 0;

  .testimonial-block {
    max-width: 750px;
    width: 98%;
    margin: 0 auto;

    @media #{$tablet} {
      @include flexbox;

      blockquote {
        -webkit-flex: 1;
        flex: 1;
      }
    }
  }
}

.folio {
  padding: 40px 0px;
  list-style: none;
  @extend %flexbox;
  @include flex-flow(wrap);
  text-align: center;
  li {
    padding: 30px 20px;
    box-sizing: border-box;
    width: 100%;
    margin:8px 10px;
    border:1px solid #dadada;
    border-radius:3px;
    box-shadow:0px 0.3px 0.9px rgba(0, 0, 0, 0.108), 0px 1.6px 3.6px rgba(0, 0, 0, 0.132);
    @media #{$tablet} {
      @include flex(1, 1, 45%);
    }

    @media #{$desktop} {
      @include flex(1, 1, 29%);
    }
  }
  .name {
         font-weight:bold;
         margin:10px 0;
    a {
        color:$brand-color;
        font-size:1.2rem;
        }
    a:hover {
        text-decoration:none;
        color:#301bc6;
        }
    }
   .square {
        height:200px;
        overflow:hidden;
       }
  .square img {
        transition: transform .5s ease;
      }
   .square img:hover {
        transform: scale(1.5);
       }
}

.hero {
  color: #ffffff;
  text-align: center;
  background: linear-gradient(to bottom, $middle-gradient-color 0%, $secondary-brand-color 100%) no-repeat $secondary-brand-color;
  padding-top: 50px;
  background-image: url("../images/geometric-bg.jpg");
  background-size:cover;

  p {
    color: #fff;
    text-shadow: 2px 2px 6px #000;
    font-size:1.3rem;
  }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,300);
* {
	margin: 0;
	padding: 0;
}
.hero {
    @media #{$desktop} {
      height: 55vh !important;
    }
    @media #{$tablet} {
      height: 100vh;
    }
	background: linear-gradient(20deg, rgba(35, 36, 125, 0.35), rgba(11, 8, 21, 0.75), rgba(234, 236, 198, 0.6), rgba(89, 3, 27, 0.6), rgba(19, 21, 20, 0.72)) 0 0/1000% no-repeat, url(../images/geometric-bg.jpg) 0 0/cover no-repeat;                           
}
@-webkit-keyframes gradientAnimation {
	0%   { background-position: 0% 30%, 0 0;}
	50%  { background-position: 100% 70%, 0 0;}
	100% { background-position: 0% 30%, 0 0;}
}
@keyframes gradientAnimation {
	0%   { background-position: 0% 30%, 0 0;}
	50%  { background-position: 100% 70%, 0 0;}
	100% { background-position: 0% 30%, 0 0;}
}
/*
h1 {
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	color: #fff;
	font : normal 600 72px/1 'Open Sans', sans-serif;
	text-align: center;
	white-space: nowrap;
}
h1 span {
	display: block;
	margin-top: 1em;
	font-size: 40px;
	font-weight: 300;
} */
@media #{$desktop} {
  .flex {
    @include flexbox;
    align-items: center;
    flex-direction: row;

    .text, .image {
      -webkit-flex: 1;
      flex: 1;
      padding: 0 20px;
    }
  }

  .content section:nth-child(even) .flex {
    flex-direction: row-reverse;
  }
}
