.footer-links {
  width: 100%;
  margin: 10px;
  padding: 0;

  @media #{$tablet} {
    -webkit-flex: 1 0 180px;
    flex: 1 0 180px;
  }

  li {
    list-style: none;
    margin: 15px auto;

    @media #{$tablet} {
      max-width: 150px;
    }
    a {

      &:hover {
        text-decoration: none;
      }
      svg {
        fill: #999;
        margin-right: 10px;
        transition: fill 0.2s ease;
        vertical-align: middle;
        position: relative;
        top: -2px;
        width: 22px;
        height: 22px;
      }

      &:hover svg {
        fill: #fff;
      }

      &.twitter-icon:hover svg {
        fill: #55acee;
      }

      &.google-plus-icon:hover svg {
        fill: #db4437;
      }

      &.youtube-icon:hover svg {
        fill: #cd201f;
      }

      &.instagram-icon:hover svg {
        fill: #f167f5;
      }

      &.linkedin-icon:hover svg {
        fill: #0077b5;
      }

      &.pinterest-icon:hover svg {
        fill: #bd081c;
      }

      &.rss-icon:hover svg {
        fill: #f26522;
      }
    }
  }
}

footer {
  padding: 50px 0 50px 0;
  font-size: 1.1em;
  position: relative;
  background: linear-gradient(to right, #1D1D2F 0%, #051631 100%) no-repeat #070e15;
  background-image: url("../images/techbg.jpg");
  background-size:cover;
  color: #fff;

  .copyright {
    font-size: .8em;
    margin: 0 auto;

    @media #{$tablet} {
      text-align: center;
    }

  }

  &,
  a {
    color: #fff;
  }

  h2 {
    font-size: 1.4em;
    margin: 30px 0;
    color: #ccc;
  }

  .footer-columns {
    @extend %flexbox;
    @include flex-flow(wrap);
    margin: -10px -10px 10px -10px;
  }

  a {
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
 .copyright {
    a { 
        color:#444;
     }
   }

  .legal-line {
    width: 100%;
    padding: 30px 0;
    margin: 0;
    background-color: #222527;

    a {
      font-weight: 600;
    }
  }
}
.links{
    display:block ruby;
    text-align:center;

ul { 
  margin: 150px auto 0; 
  padding: 0; 
  list-style: none; 
  display: table;
  width: 600px;
  text-align: center;
}
li { 
  display: table-cell; 
  position: relative; 
  padding: 15px 0;
}
li::after { /* add the new bullet point */
  display: inline-block;
    content: '';
    -webkit-border-radius: 0.375rem;
    border-radius: 0.375rem;
    height: 0.75rem;
    width: 0.75rem;
    margin-right: 0.5rem;
    background-color: #bdbdbd;
}
a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-size:0.7em;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
a:hover:after { 
  width: 100%; 
  left: 0; 
}
}
@media screen and (max-height: 300px) {
	ul {
		margin-top: 40px;
	}
}
