.nav-open nav {
  border-bottom: 1px dotted rgba(255, 255, 255, .2);
  padding: 10px 0;
  a {
    display: block;
  }

  @media #{$mid-point} {
    border: 0;
    padding: 0 20px;

    a {
      display: inline;
    }
  }
}

nav {
  text-transform: uppercase;
  font-size: .8em;
  width: 100%;

  @media #{$mid-point} {
    text-align: right;
    position: absolute;
    top: 13px;
    right: 0;
    padding: 0 20px;
  }


  a {
    margin: 0 3px;
    padding: 20px 10px;
    border-bottom: 1px solid rgba(255,255,255,0);
    color: rgba(255,255,255,.8);
    transition: .2s ease-in-out;
    display: none;

    @media #{$mid-point} {
      display: inline;
      padding: 10px;
    }


    &.nav-toggle {
      display: inline;
      position: absolute;
      right: 10px;
      top: -22px;
      font-size: 1.9em;
      border: 0;

      @media #{$mid-point} {
        display: none;
      }

      &:hover {
        border: 0;
      }
    }
  }

  a:hover {

    border-bottom: 1px solid rgba(255,255,255,0.7);
    color: #fff;
    -webkit-transform:scaleX(1);
    -ms-transform:scaleX(1);
    transform:scaleX(1);
    -webkit-transition:-webkit-transform .45s ease-in-out;
    transition:-webkit-transform .45s ease-in-out;
  }
  @media #{$mid-point} {
    a.highlight {
      border: 1px #ccc solid;
      border-radius: 5px;

      &:hover {
        background: #fff;
        color: $brand-color;
      }
    }
  }

  a.active {
    color: #fff;
  }

}
