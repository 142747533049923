.container, .text-container {
  margin: 0 auto;
  position: relative;
  padding: 0 20px;
}

.text-container {
  max-width: 750px;
}

.container {
  max-width: 1140px;

  &.max-container {
    max-width: 100%;
    padding: 0;
  }
}
.full {
    text-align:justify;
    }
.divider-holder {
    .divider-line{
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100px;
        margin-left: auto;
        margin-right: auto;
        height: 2px;
        background: #dd3333;
    }
}
.page-heading {text-align:center;}
header {
  color: #fff;
  padding: 20px 0;
  background: $brand-color; /* Old browsers */
  background: linear-gradient(to right, #063871 0%, #1278ee 100%) no-repeat #006ae2;

  a {
    color: #fff;
    text-decoration: none;
    z-index: 1;
    position: relative;

    &:hover {
      text-decoration: none;
    }
  }

  .company-name {
    font-size: 1.7em;
    line-height: 0;

    a {
      display: inline-block;
    }

    img {
      display: block;
      width: auto;
    }
  }
}
.fullcover {
    margin-bottom:30px;

    img {
       width:100%;
    }
}
.content {
  background: #fff;
  padding: 0;
  position: relative;
}

.screenshot{
    max-width: 250px;
    display: block;
    margin: 22px auto;
    position: relative;
    height: 70px;
    width: auto;
}

section {
  padding: 100px 0;
}

section + section {
  padding-top: 0;
}

.subtext {
  margin-top: 30px;
  text-align: justify;
}


.cta {
  margin: 60px 0;
}
.page h2, .portfolio h2{
    padding-top:40px;
    text-align:center;
}
blockquote {
  padding: 18px 25px;
  margin: 0;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;

  .author {
    display: block;
    font-weight: bold;
    margin: 10px 0 0 0;
    font-size: .85em;
    font-style: normal;
  }

  p {
    display: inline;
  }
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.square-image {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 25px auto 0 auto;
  position: relative;
  border-radius: 200px;
  border:4px solid #fff;
}

.square-team {
  width: 150px;
  height: 150px;
  overflow: hidden;
  margin: 25px auto 0 auto;
  border: 3px solid #b24880;
  border-radius: 200px;
  display:inline;
  float:right;
}

.page {
  margin-bottom: 0;
  padding-top:0;
  padding-bottom: 40px;
  background:#f5f5f5;
}
.portfolio {
  margin-bottom: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  background:#fff;
}
.member-desc {
  position: relative;
}
.center-text {
  text-align: center;
}

.editor-link {
    display: none;
  margin-top: 0;
  .btn {
    border: 0;
    border-radius: 2px;
    width: 100%;
    max-width: 500px;
    box-sizing: border-box;
    font-size: 2rem;
    text-decoration: none;
    padding: 10px 15px;
    margin: 0;
    font-size: 18px;
    cursor: pointer;
    background-color: #f7e064;
    color: #333;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: #f4d525;
      color: #333;
    }
  }

}
